import { Component, computed, inject, model, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { IonContent, ModalController } from '@ionic/angular/standalone';
import { UserService } from '../../core/services/user.service';

@Component({
    imports: [FormsModule, IonContent],
    template: `
    <ion-content class="ion-padding">
      <div class="leading-5 my-4">
        <p>
          Necesitamos su número de teléfono para asegurarnos de que podamos
          comunicarnos con usted sobre la entrega de su pedido.
        </p>
        <p>Por favor proporcione su número de teléfono a continuación:</p>
      </div>
      <form class="my-2">
        <input
          [(ngModel)]="phone"
          class="w-full border-2 border-black rounded-xl h-12  text-center"
          name="phone"
          type="tel"
        />
        @if (!isValid()) {
          <div class="leading-5 text-sm text-alizarin-crimson">
            <span>Por favor introduzca un numero de teléfono valido.</span>
          </div>
        }
      </form>

      <button
        class="bg-catalina-blue text-white font-bold w-full h-12 rounded-full my-8 disabled:bg-opacity-30"
        [disabled]="!isValid() || saving()"
        (click)="dismiss()"
      >
        Continuar
      </button>
    </ion-content>
  `
})
export class RequestContactInfoModal {
  private readonly modalRef = inject(ModalController);
  private readonly userService = inject(UserService);

  readonly phone = model('');

  readonly isValid = computed(() => this.phone().length > 7);
  protected readonly saving = signal(false);

  async dismiss() {
    if (!this.isValid()) {
      return this.modalRef.dismiss(false);
    }
    try {
      this.saving.set(true);
      await this.userService.update({ phone: this.phone(), whatsapp: this.phone() });
      this.modalRef.dismiss(true);
    } catch {
      this.modalRef.dismiss(false);
    } finally {
      this.saving.set(false);
    }
  }
}
