import { inject } from '@angular/core';
import { CanMatchFn } from '@angular/router';
import { ModalController } from '@ionic/angular/standalone';
import { firstValueFrom } from 'rxjs';
import { UserService } from '../../core/services/user.service';
import { RequestContactInfoModal } from '../modals/request-contact-info.modal';

export const requiresContactInfo: CanMatchFn = async () => {
  const userService = inject(UserService);
  const modalController = inject(ModalController);
  const user = await firstValueFrom(userService.user$);

  if (Boolean(user?.phone) || Boolean(user?.whatsapp)) {
    return true;
  }

  const modal = await modalController.create({
    component: RequestContactInfoModal,
    breakpoints: [0, 0.7, 0.9],
    initialBreakpoint: 0.9,
  });
  await modal.present();
  const { data } = await modal.onWillDismiss<boolean>();
  return Boolean(data);
};
