import { Resource } from '@angular/core';

/**
 * Waits until Resource.isLoading() returns false.
 * @param resource The resource to wait for.
 * @param frequency Time in milliseconds to check the resource value.
 * @param timeout Time in milliseconds to resolve if the resource never stops loading.
 */
export const waitUntilResourceLoaded = (
  resource: Resource<unknown>,
  frequency = 200,
  timeout = 5000,
): Promise<void> => {
  return new Promise<void>((resolve) => {
    const startTime = performance.now();
    const interval = setInterval(() => {
      const hasTimedOut = performance.now() - startTime > timeout;
      if (!resource.isLoading() || hasTimedOut) {
        clearInterval(interval);
        resolve();
      }
    }, frequency);
  });
};
