import { Routes } from '@angular/router';
import { authGuard, noAuthGuard } from './core/auth/auth.guard';
import { productResolver } from './features/products/details/data-access/product-resolver';
import { hasBeenInvited } from './shared/guards/has-been-invited';
import { requiresContactInfo } from './shared/guards/requires-contact-info';

export enum AppPath {
  Root = '',
  Auth = 'auth',
  Cart = 'cart',
  Checkout = 'checkout',
  Discounts = 'discounts',
  Features = 'features',
  Onboarding = 'onboarding',
  Products = 'products',
}

export const routes: Routes = [
  {
    path: AppPath.Root,
    redirectTo: AppPath.Auth,
    pathMatch: 'full',
  },
  {
    path: AppPath.Auth,
    loadChildren: () => import('./auth/auth.routes').then((m) => m.routes),
    canMatch: [noAuthGuard],
  },
  {
    path: AppPath.Features,
    loadComponent: () =>
      import('./features/features.page').then((m) => m.FeaturesPage),
    loadChildren: () =>
      import('./features/features.routes').then((m) => m.routes),
    canMatch: [hasBeenInvited],
  },
  {
    path: AppPath.Cart,
    loadComponent: () =>
      import('./features/cart/cart.component').then((m) => m.CartComponent),
    canMatch: [authGuard],
  },
  {
    path: AppPath.Checkout,
    loadComponent: () =>
      import('./features/checkout/checkout.component').then(
        (m) => m.CheckoutComponent,
      ),
    loadChildren: () =>
      import('./features/checkout/checkout.routes').then((m) => m.routes),
    canMatch: [authGuard, requiresContactInfo],
  },
  {
    path: `${AppPath.Products}/:id`,
    loadComponent: () =>
      import('./features/products/details/product-details.component').then(
        (m) => m.ProductDetailsComponent,
      ),
    resolve: {
      product: productResolver,
      // cartItem: productCartItemResolver
    },
  },
  {
    path: 'p/:id',
    redirectTo: `${AppPath.Products}/:id`,
    pathMatch: 'full',
  },
  {
    path: AppPath.Products,
    loadComponent: () =>
      import('./features/products/products.component').then(
        (c) => c.ProductsComponent,
      ),
  },
  {
    path: AppPath.Discounts,
    loadComponent: () =>
      import('./features/discounts/discounts.component').then(
        (c) => c.DiscountsComponent,
      ),
  },
  {
    path: AppPath.Onboarding,
    loadChildren: async () =>
      (await import('./onboarding/onboarding.routes')).routes,
    canMatch: [authGuard],
  },
];
