import { inject } from '@angular/core';
import { CanMatchFn, RedirectCommand, Router } from '@angular/router';
import { AppPath } from '../../app.routes';
import { UserService } from '../../core/services/user.service';
import { waitUntilResourceLoaded } from '../utils/wait-until-resource-loaded';

export const hasBeenInvited: CanMatchFn = async () => {
  const router = inject(Router);
  const user = inject(UserService);
  await waitUntilResourceLoaded(user.resource);
  return (
    Boolean(user.resource.value()?.data.invited_by) ||
    new RedirectCommand(router.createUrlTree(['/', AppPath.Onboarding]))
  );
};
